import { useConnect } from './hooks'
import { Header } from './components/Header'
import { Layout } from 'antd'
// import { useWeb3React } from '@web3-react/core'
// import { Web3Provider } from '@ethersproject/providers'
// import { hexlify } from '@ethersproject/bytes'
// import { Decimal } from '@liquity/lib-base'

export default function App() {
  useConnect()
  // const { library: provider, account } = useWeb3React<Web3Provider>()

  // const transaction = async () => {
  //   if (provider && account) {
  //     const signer = await provider.getSigner(account)
  //     // const signedTransaction = await signer.signTransaction({
  //     //   from: account,
  //     //   nonce: '1',
  //     //   to: '',
  //     //   value: '0.001',
  //     // })
  //     const ethAmount = Decimal.from(0.01)
  //     const transactionResponse = await signer.sendUncheckedTransaction({
  //       from: account,
  //       to: '',
  //       gasPrice: hexlify(2000),
  //       value: ethAmount.hex,
  //     })
  //     console.log(transactionResponse)
  //   }
  // }

  return (
    <Layout>
      <Header />
      {/* <button onClick={transaction}>create transaction</button> */}
      <div className="h-screen flex items-center justify-center">
        <span className="text-2xl text-center">To Be Continued.</span>
      </div>
    </Layout>
  )
}
