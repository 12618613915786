export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42
}

export const NetworkName: Record<ChainId | number, string> = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.ROPSTEN]: 'ropsten',
  [ChainId.RINKEBY]: 'rinkeby',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.KOVAN]: 'kovan',
}

export const NetworkColor: Record<ChainId | number, string> = {
  [ChainId.MAINNET]: '#2db7f5',
  [ChainId.ROPSTEN]: '#fff',
  [ChainId.RINKEBY]: '#fff',
  [ChainId.GOERLI]: '#fff',
  [ChainId.KOVAN]: '#531dab',
}

export const NetworkContextName = 'NETWORK'