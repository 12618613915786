import { StoreProvider } from '../store'
import { EthProvider } from './Provider'

const Provider: React.FC = (props) => {
  return (
    <StoreProvider>
      <EthProvider>{props.children}</EthProvider>
    </StoreProvider>
  )
}

export default Provider
