import { Avatar, Button, Card, Layout, Modal, Spin, Tag } from 'antd'
import React, { useState, useMemo, useContext, useEffect } from 'react'
import multiavatar from '@multiavatar/multiavatar'
import MetaMaskFox from '../../assets/images/metamask-fox.svg'
import { Brand } from '../ui'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../connectors'
import { Actions, StoreContext } from '../../store'
import { NetworkColor, NetworkName } from '../../constants'

const { Header: LayoutHeader } = Layout

export const Header: React.FC = () => {
  const [walletModalVisible, setWalletModalVisible] = useState(false)
  const { dispatch, activatingConnector } = useContext(StoreContext)
  const { account, activate, chainId } = useWeb3React()
  const connected = useMemo(() => Boolean(account), [account])
  // @notice: temporary only support injected provider MeteMask
  const connecting = useMemo(() => activatingConnector === injected && !account, [account, activatingConnector])

  useEffect(() => {
    if (connected && walletModalVisible) {
      setWalletModalVisible(false)
    }
  }, [connected, walletModalVisible])

  const connectWallet = () => {
    if (connecting) return
    dispatch(Actions.Activating, injected)
    activate(injected, () => dispatch(Actions.Activating, undefined))
  }

  return (
    <>
      <LayoutHeader className="flex items-center justify-between px-5 md:px-20">
        <Brand />
        <div className="flex space-x-4">
          {connected && (
            <Tag color={NetworkColor[chainId!]} className="flex items-center hidden xs:inline-block">
              Network: {NetworkName[chainId!] || 'Unknown Network'}
            </Tag>
          )}

          <Button type="primary" onClick={() => setWalletModalVisible((v) => !v)}>
            {/* @TODO: Address Clipboard */}
            <span className="max-w-5 xs:max-w-10 truncate">{connected ? account : 'Connect Wallet'}</span>
          </Button>

          {connected && <Avatar src={<img alt="avatar" src={`data:image/svg+xml;utf8,${encodeURIComponent(multiavatar(account!))}`} />} />}
        </div>
      </LayoutHeader>
      <Modal visible={walletModalVisible} footer={null} title="Select Wallet" onCancel={() => setWalletModalVisible(false)}>
        <Card hoverable className="max-h-20" onClick={connectWallet}>
          <Spin spinning={connecting} tip="Connecting to MetaMask">
            <div className="flex items-center">
              <img src={MetaMaskFox} alt="metamask" />
              <p className="mb-0 ml-5 text-lg font-medium"> MetaMask {connecting}</p>
            </div>
          </Spin>
        </Card>
      </Modal>
    </>
  )
}
