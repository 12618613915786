import { InjectedConnector } from '@web3-react/injected-connector'
import React, { createContext, useEffect, useReducer } from 'react'
import { injected } from '../connectors'
import { ChainId } from '../constants'

interface Store {
  isAuthorized: boolean
  address: string
  chainId?: ChainId
  activatingConnector?: InjectedConnector
}

const initialState: Store = {
  isAuthorized: false,
  address: '',
  chainId: undefined,
  activatingConnector: undefined,
}

interface Context extends Store {
  dispatch: (action: Actions, payload?: any) => void
}

export const StoreContext = createContext<Context>({ ...initialState, dispatch: () => null })

export enum Actions {
  Authorization,
  CancelAuthorization,
  SelectedAddress,
  Activating,
}

const reducer = (state: Store, action: { type: Actions; payload: any }): Store => {
  const { type, payload } = action
  switch (type) {
    case Actions.Authorization:
      return { ...state, isAuthorized: true, ...payload }
    case Actions.CancelAuthorization:
      return { ...state, isAuthorized: false }
    case Actions.Activating:
      return { ...state, activatingConnector: payload }
    default:
      return state
  }
}

export const useStore = () => {
  const [state, _dispatch] = useReducer(reducer, initialState)
  const dispatch = (action: Actions, payload?: any) => _dispatch({ type: action, payload })

  useEffect(() => {
    const checkAuth = async () => {
      const authorized = await injected.isAuthorized()
      let connector
      if (authorized) {
        connector = await injected.activate()
        console.log('connector', connector)
      }
      dispatch(Actions.Authorization, connector ? { address: connector.account, chainId: connector.chainId } : {})
    }
    checkAuth()
  }, [])

  return {
    ...state,
    dispatch,
  }
}

export const StoreProvider: React.FC = (props) => {
  const store = useStore()
  return <StoreContext.Provider value={store}>{props.children}</StoreContext.Provider>
}
