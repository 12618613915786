import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import React from 'react'

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

export const EthProvider: React.FC = (props) => {
  return <Web3ReactProvider getLibrary={getLibrary}>{props.children}</Web3ReactProvider>
}
