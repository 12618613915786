import React from 'react'
import Logo from '../../assets/images/logo.svg'

export const Brand: React.FC = () => {
  return (
    <div>
      <img src={Logo} alt="Liquity" />
    </div>
  )
}
