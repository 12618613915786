import { useState, useEffect, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../connectors'
import { Web3Provider } from '@ethersproject/providers'
import { Actions, StoreContext } from '../store'

/**
 * hooks: useActiveConnect
 *
 * detect if provider has connected and activated before, if not, you can use `useInactiveListener`
 * to initialize inactive event
 *
 * @returns isConnected [boolean]
 */
export function useActiveConnect() {
  const { activate, active } = useWeb3React()

  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setIsConnected(true)
        })
      } else {
        setIsConnected(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!isConnected && active) {
      setIsConnected(true)
    }
  }, [isConnected, active])

  return isConnected
}

export function useInactiveListener(isActivated: boolean = false) {
  const { active, error, activate } = useWeb3React()

  useEffect((): any => {
    const { ethereum } = window as any
    if (ethereum && ethereum.on && !active && !error && !isActivated) {
      const handleConnect = () => {
        console.log('Handling `connect` event')
        activate(injected)
      }
      const handleChainChanged = (chainId: string | number) => {
        console.log('Handling `chainChanged` event with payload', chainId)
        activate(injected)
      }
      const handleAccountsChanged = (accounts: string[]) => {
        console.log('Handling `accountsChanged` event with payload', accounts)
        if (accounts.length > 0) {
          activate(injected)
        }
      }
      const handleNetworkChanged = (networkId: string | number) => {
        console.log('Handling `networkChanged` event with payload', networkId)
        activate(injected)
      }

      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }
  }, [active, error, isActivated, activate])
}

export function useConnect() {
  const context = useWeb3React<Web3Provider>()
  const { connector } = context
  const { activatingConnector, dispatch } = useContext(StoreContext)
  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      dispatch(Actions.Activating, undefined)
    }
  }, [activatingConnector, connector, dispatch])

  const isConnected = useActiveConnect()
  useInactiveListener(!isConnected || !!activatingConnector)
}
